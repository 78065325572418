import styled, { css } from 'styled-components';
import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';
import { lighten } from 'polished';

export const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.primary};
  font-size: ${(props) => props.fontSize || '14px'};
  color: ${colors.white};
  text-decoration: none;
  border: 1px solid ${colors.red300};
  border-radius: 2px;
  background-color: ${colors.red300};
  transition: all 0.2s ease-out;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  width: ${(props) => props.width || '15em'};
  height: ${(props) => props.height};
  -webkit-appearance: none;

  @media screen and (max-width: 980px) {
    padding: 4px 8px;
    font-size: ${(props) => props.fontSize || '12px'};
  }

  &:hover {
    color: ${colors.white};
    background-color: ${lighten(0.2, colors.red300)};
  }

  ${(props) =>
    props.callToAction &&
    props.withIcon &&
    css`
      &:after {
        display: inline-block;
        margin-left: 10px;
        content: '→';
        font-size: 24px;
        transition: transform 0.2s ease-out;
        transform: ${(props) =>
          props.rotateIcon ? 'rotate(-90deg)' : 'rotate(0deg)'};
      }
    `}

  ${(props) =>
    props.variant === 'light-outline' &&
    css`
      color: ${colors.white};
      border: 1px solid ${colors.white};
      background-color: transparent;

      &:hover {
        color: ${colors.gray300};
        background-color: ${colors.white};
      }
    `}

  ${(props) =>
    props.variant === 'dark-outline' &&
    css`
      color: ${colors.gray300};
      border: 1px solid ${colors.gray300};
      background-color: transparent;

      &:hover {
        color: ${colors.white};
        background-color: ${colors.gray300};
      }
    `}

  ${(props) =>
    props.variant === 'dark' &&
    css`
      color: white;
      border: 1px solid black;
      background-color: black;

      &:hover {
        background-color: ${colors.red300};
        border: 1px solid ${colors.red300};
      }
    `}

  ${(props) =>
    props.variant === 'red400-outline' &&
    css`
      color: ${colors.red400};
      border: 1px solid ${colors.red400};
      background-color: transparent;

      &:hover {
        color: ${colors.white};
        background-color: ${colors.red400};
      }
    `}
`;
