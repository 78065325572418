import React from 'react';
import { StyledButton } from './styled';

const Button = React.forwardRef(
  (
    { children, callToAction, variant, scrollToTop, ...props },
    ref
  ) => {
    const scroll = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
    return (
      <StyledButton
        ref={ref}
        callToAction={callToAction}
        onClick={scrollToTop ? scroll : null}
        variant={variant}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  callToAction: false,
  variant: 'primary'
};

export default Button;
